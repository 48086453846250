import { createSlice } from '@reduxjs/toolkit'

const initialState = {

    cartItems: [],
    totalAmount:0,
    totalQuantity:0
}

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addItem:(state,action)=>{
        const newItem = action.payload
        const existingItem = state.cartItems.find(item => item.id === newItem.id
        );
        state.totalQuantity++

        if(!existingItem){
            state.cartItems.push({
                id: newItem.id,
                productName:newItem.productName,
                shortDesc:newItem.shortDesc,
                imgUrl: newItem.imgUrl,
                price: newItem.price,
                quantity: 1,
                totalPrice: newItem.price
            })
        }
        else{ 
            existingItem.quantity++
            existingItem.totalPrice = Number(existingItem.totalPrice) + Number(newItem.price)
        }
        state.totalAmount = state.cartItems.reduce((total, item)=> total + Number(item.price)*Number(item.quantity),0
        );
    },
    deleteItem: (state, action) => {
        const id = action.payload;
        const existingItemIndex = state.cartItems.findIndex((item) => item.id === id);
      
        if (existingItemIndex !== -1) {
          const existingItem = state.cartItems[existingItemIndex];
          if (existingItem.quantity === 1) {
            state.cartItems.splice(existingItemIndex, 1);
          } else {
            existingItem.quantity--;
            existingItem.totalPrice = Number(existingItem.totalPrice) - Number(existingItem.price);
          }
          state.totalQuantity--;
          state.totalAmount = state.cartItems.reduce((total, item) => total + Number(item.price) * Number(item.quantity), 0);
        }
      
        localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
      },
        loadCartItems: (state) => {
        const cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
        const totalAmount = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
        return {
          ...state,
          cartItems,
          totalAmount
        };
      }   
  },
});

export const cartActions = cartSlice.actions

export default cartSlice.reducer;