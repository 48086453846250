import React, { useState } from 'react';
import ProductCard from './ProductCard';
import {motion} from 'framer-motion';

const ProductsList = ({ data, category }) => {
  const [showCount, setShowCount] = useState(6);

  const handleViewMore = () => {
    setShowCount(prevCount => prevCount + 6);
  };

  return (
    <>
      <h3>{category}</h3>
      {data?.slice(0, showCount).map((item, index) => (
        <ProductCard item={item} key={index} />
      ))}
      <div style={{ textAlign: "center" }}>
        {showCount < data?.length && (
          <motion.button whileTap={{scale:1.1}} className="buy__btn w-50 mt-3" onClick={handleViewMore} style={{boxShadow:"1px 1px 1px yellow"}}>View More</motion.button>
        )}
      </div>
    </>
  );
};

export default ProductsList;
