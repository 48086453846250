

import React , {useEffect} from 'react';
import '../styles/cart.css';
import Helmet from '../components/Helmet/Helmet';
import CommonSection from '../components/UI/CommonSection';
import { Container,Row,Col } from 'reactstrap';

import { motion } from 'framer-motion';
import {cartActions} from '../redux/slices/cartSlice';
import { useSelector,useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

const Cart = () => {
    const dispatch = useDispatch();
    const cartItems = useSelector(state=> state.cart.cartItems);
    const totalAmount = useSelector((state) => state.cart.totalAmount)
    
    useEffect(() => {
        dispatch(cartActions.loadCartItems());
      }, [dispatch]);

      const generateWhatsAppMessage = (cartItems) => {
        let message = "Hello, I would like to order:\n";
        cartItems.forEach((item) => {
            message += `- ${item.productName} (Quantity:${item.quantity}) (model number: ${item.shortDesc}).\n`;});
        return encodeURIComponent(message);
      };
      
      const handleCheckout = () => {
        const message = generateWhatsAppMessage(cartItems);
        window.open(`https://wa.me/2349020849300?text=${message}`);
      };
      
    
    return (
    <Helmet title='Cart'>
        <CommonSection title='Shopping Cart'/>
        <section>
            <Container>
                <Row>
                    <Col lg='9'>
                    {
                        cartItems.length===0 ? <h2 className='fs-4 text-center'>No items added to the cart</h2> : (
                        <table className='table bordered'>
                            <thead>
                                <tr>
                                    <th>Image</th>
                                    <th>Title</th>
                                    <th>Model Number</th>
                                    {/* <th>Price</th> */}
                                    <th>Qty</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>

                            <tbody>
                            {
                                cartItems.map((item,index)=>(
                                    <Tr item={item} key={index} />
                                ))
                            }
                            </tbody>
                        </table>
                        )}
                    </Col>
                    <Col lg='3'>
                        <div>
                            <h6 className='d-flex align-items-center justify-content-between mt-3'>Taxes and Shipping will calculate in checkout
                                {/* <span className='fs-4 fw-bold'>&#8358;{totalAmount}</span> */}
                            </h6>
                        </div>
                        {/* <p className='fs-6 mt-2'>taxes and shipping will calculate in checkout</p> */}
                        <div>
                            <button className="buy__btn w-100" onClick={handleCheckout}>Request for Price</button>
                            <button className="buy__btn w-100 mt-3"><Link to='/shop'>Continue Shopping</Link></button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    </Helmet>
    );
};

const Tr = ({item})=>{
    const dispatch = useDispatch()
    const deleteProduct = () => {
        dispatch(cartActions.deleteItem(item.id))
    }
    return (
        <tr>
            <td><img src={item.imgUrl} alt="" /></td>
            <td>{item.productName}</td>
            <td>{item.shortDesc}</td>
            {/* <td>&#8358;{item.price}</td> */}
            <td>{item.quantity}px</td>
            <td><motion.i whileTap={{scale:1.2}} onClick={deleteProduct} class="ri-delete-bin-line"></motion.i></td>
        </tr>


    )
}

export default Cart;