import React, {useState, useEffect,} from 'react';
import { Carousel } from 'bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css'
import Helmet from '../components/Helmet/Helmet';
import '../styles/home.css';

import slide1 from '../assets/images/slideseven.jpg';
import slide2 from '../assets/images/slidetwo.jpg';
import slide3 from '../assets/images/slideeleven.jpg';
import slide5 from '../assets/images/slidefive.jpg';
import slide6 from '../assets/images/slidesix.jpg';


import { Container, Row , Col } from 'reactstrap';

import Services from '../services/Services';
import ProductsList from '../components/UI/ProductsList';
import Slide from '../services/Slide';
import useGetData from '../custom-hooks/useGetData';

const Home = () => {

    const {data: products,loading} = useGetData('products'); 

    const [executiveTableProducts, setExecutiveTableProducts] = useState([]);
    const [sofasProducts, setSofasProducts] = useState([]);
    const [workstationProducts, setWorkstationProducts] = useState([]);
    const [receptionTableProducts, setReceptionTableProducts] = useState([]);
    const [bookshelvesProducts, setBookshelvesProducts] = useState([]);
    const [massagingChairProducts, setMassagingChairProducts] = useState([]);

    const year = new Date().getFullYear();

    useEffect(()=>{

        AOS.init();

        const filteredExecutiveTableProducts = products.filter(
        (item)=> item.category ==="executive table"
        );
        
        const filteredSofasProducts = products.filter(
        (item)=> item.category ==="sofas"
        );

        const filteredWorkstationProducts = products.filter(
        (item)=> item.category ==="workstation"
        );
    
        const filteredReceptionTableProducts = products.filter(
        (item)=> item.category ==="reception table"
        );
        const filteredBookshelvesProducts = products.filter(
        (item)=> item.category ==="bookshelves"
        );
        const filteredMassagingChairProducts = products.filter(
        (item)=> item.category ==="massaging chair"
        );

        setExecutiveTableProducts(filteredExecutiveTableProducts);
        setSofasProducts(filteredSofasProducts);
        setWorkstationProducts(filteredWorkstationProducts);
        setReceptionTableProducts(filteredReceptionTableProducts);
        setBookshelvesProducts(filteredBookshelvesProducts);
        setMassagingChairProducts(filteredMassagingChairProducts);
    }, [products]);

const [activeSlide, setActiveSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveSlide(activeSlide => (activeSlide + 1) % 5);
    }, 5000); // change slide every 5 seconds

    return () => clearInterval(interval);
  }, []);

  const handleSlideChange = (slideIndex) => {
    setActiveSlide(slideIndex);
  };
return (
        <Helmet title={"Home"}>
        <Slide/>
        <div id="carouselExampleCaptions" className="carousel slide ms-4 me-4">
        <div className="carousel-indicators">
        <button type="button" onClick={() => handleSlideChange(0)} className={`btn ${activeSlide === 0 ? 'active' : ''}`} />
        <button type="button" onClick={() => handleSlideChange(1)} className={`btn ${activeSlide === 1 ? 'active' : ''}`} />
        <button type="button" onClick={() => handleSlideChange(2)} className={`btn ${activeSlide === 2 ? 'active' : ''}`} />
        <button type="button" onClick={() => handleSlideChange(3)} className={`btn ${activeSlide === 3 ? 'active' : ''}`} />
        <button type="button" onClick={() => handleSlideChange(4)} className={`btn ${activeSlide === 4 ? 'active' : ''}`} />        </div>
        <div className="carousel-inner">
            <div className={`carousel-item ${activeSlide === 0 ? 'active' : ''}`}>
            <img src={slide1} className="d-block w-100 " alt="..."/>
            <div className="carousel-caption d-none d-md-block">
                <h1>NEW!</h1>
                <span>CREATIVE DESIGN FURNITURE</span>
                <br></br>
                <span style={{background:"#000"}}>EXECUTIVE SOFA</span>
            </div>
            </div>
            <div className={`carousel-item ${activeSlide === 1 ? 'active' : ''}`}>
            <img src={slide2} className="d-block w-100" alt="..."/>
            <div className="carousel-caption d-none d-md-block">
                <h1>NEW!</h1>
                <span>CREATIVE DESIGN FURNITURE</span>
                <br></br>
                <span style={{background:"#000"}}>LOUNGE CHAIR</span>
            </div>
            </div>
            <div className={`carousel-item ${activeSlide === 2 ? 'active' : ''}`}>
            <img src={slide3} className="d-block w-100" alt="..."/>
            <div className="carousel-caption d-none d-md-block">
                <h1>NEW!</h1>
                <span>CREATIVE DESIGN FURNITURE</span>
                <br></br>
                <span style={{background:"#000"}}>EXECUTIVE TABLE</span>
            </div>
            </div>
            <div className={`carousel-item ${activeSlide === 3 ? 'active' : ''}`}>
            <img src={slide5} className="d-block w-100" alt="..."/>
            <div className="carousel-caption d-none d-md-block">
                <h1>NEW!!</h1>
                <span>CREATIVE DESIGN FURNITURE</span>
                <br></br>
                <span style={{background:"#000"}}>4-MAN WORKSTATION</span>
            </div>
            </div>
            <div className={`carousel-item ${activeSlide === 4 ? 'active' : ''}`}>
            <img src={slide6} className="d-block w-100" alt="..."/>
            <div className="carousel-caption d-none d-md-block">
                <h1>NEW!</h1>
                <span>CREATIVE DESIGN FURNITURE</span>
                <br></br>
                <span style={{background:"#000"}}>CONFERENCE TABLE</span>
            </div>
            </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
        </button>
        </div>
            <section className="trending__products" id="Sofa">
                <Container>
                    <Row>
                        <Col lg='12' className='text-center'>
                            <h2 className='section__title mb-4'>Sofas</h2>
                        </Col>
                        {
                            loading ? <div class="spinner-grow text-warning" role="status">
                            <span class="visually-hidden">Loading...</span>
                            </div> : <ProductsList data={sofasProducts}/>
                        }
                    </Row>
                </Container>
            </section>
            <section className="trending__products" id='Executive Table'>
                <Container>
                    <Row>
                        <Col lg='12' className='text-center'>
                            <h2 className='section__title mb-4'>Executive Tables</h2>
                        </Col>
                        {
                            loading ? <div class="spinner-grow text-warning" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div> : <ProductsList data={executiveTableProducts}/>
                        }
                    </Row>
                </Container>
            </section>
            <Services/>
            <section className="best__sales" id='Reception Table'>
                <Container>
                    <Row>
                        <Col lg="12" className="text-center">
                            <h2 className="section__title mb-4">Reception Tables</h2>
                        </Col>
                        {
                            loading ? <div class="spinner-grow text-warning" role="status">
                            <span class="visually-hidden">Loading...</span>
                            </div> : <ProductsList data={receptionTableProducts}/>
                        }
                    </Row>
                </Container>
            </section>
            <section className="new__arrivals" id='Bookshelf'>
                <Container>
                    <Row>
                        <Col lg="12" className='text-center mb-5'>
                            <h2 className="section__title">Book-Shelves</h2>
                        </Col>
                        {
                            loading ? <div class="spinner-grow text-warning" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                        </div> : <ProductsList data={bookshelvesProducts}/>
                        }
                    </Row>
                </Container>
            </section>
            <section className="new__arrivals">
                <Container>
                    <Row>
                        <Col lg="12" className='text-center mb-5'>
                            <h2 className="section__title">Massaging Chairs</h2>
                        </Col>
                        {
                            loading ? <div class="spinner-grow text-warning" role="status">
                            <span class="visually-hidden">Loading...</span>
                            </div> : <ProductsList data={massagingChairProducts}/>
                        }
                    </Row>
                </Container>
            </section>
            <section className="popular__category" id='WorkStation'>
                <Container>
                    <Row>
                        <Col lg="12" className='text-center mb-5'>
                            <h2 className="section__title">WorkStations</h2>
                        </Col>
                        {
                            loading ? <div class="spinner-grow text-warning" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                    </div> : <ProductsList data={workstationProducts}/>
                        }
                    </Row>
                </Container>
            </section>
    </Helmet>
    
    )
};

export default Home;