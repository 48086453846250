import React,{useState,useEffect} from 'react';
import CommonSection from '../components/UI/CommonSection';
import Helmet from '../components/Helmet/Helmet';
import { Container,Row,Col } from 'reactstrap';
import '../styles/shop.css';
import ProductsList from '../components/UI/ProductsList';

import useGetData from '../custom-hooks/useGetData';

const Shop = () => {
    const {data: products} = useGetData('products'); 
    const [selectedCategory, setSelectedCategory] = useState('');
    const [productsData, setProductsData] = useState(products);

    useEffect(() => {
    const storedCategory = localStorage.getItem('selectedCategory');
    if (storedCategory) {
      setSelectedCategory(storedCategory);
      const filteredProducts = products.filter(
        (item) => item.category === storedCategory
      );
      setProductsData(filteredProducts);
    }
  }, [products]);
    const handleFilter = (e) => {
        const filterValue = e.target.value;
        setSelectedCategory(filterValue);
        localStorage.setItem('selectedCategory', filterValue);
            if(filterValue === "sofas"){
                const filteredProducts = products.filter(
                    (item) => item.category === "sofas"
                );

                setProductsData(filteredProducts);
            }

            if(filterValue === "executive table"){
                const filteredProducts = products.filter(
                    (item) => item.category === "executive table"
                );

                setProductsData(filteredProducts);
            }

            if(filterValue === "managers table"){
                const filteredProducts = products.filter(
                    (item) => item.category === "managers table"
                );

                setProductsData(filteredProducts);
            }
            if(filterValue === "office chair"){
                const filteredProducts = products.filter(
                    (item) => item.category === "office chair"
                );

                setProductsData(filteredProducts);
            }

            if(filterValue === "workstation"){
                const filteredProducts = products.filter(
                    (item) => item.category === "workstation"
                );

                setProductsData(filteredProducts);
            }

            if(filterValue === "reception table"){
                const filteredProducts = products.filter(
                    (item) => item.category === "reception table"
                );

                setProductsData(filteredProducts);
            }
            if(filterValue === "waiting chair"){
                const filteredProducts = products.filter(
                    (item) => item.category === "waiting chair"
                );

                setProductsData(filteredProducts);
            }
            if(filterValue === "bookshelves"){
                const filteredProducts = products.filter(
                    (item) => item.category === "bookshelves"
                );

                setProductsData(filteredProducts);
            }
            if(filterValue === "credenza"){
                const filteredProducts = products.filter(
                    (item) => item.category === "credenza"
                );

                setProductsData(filteredProducts);
            }
            if(filterValue === "lounge chair"){
                const filteredProducts = products.filter(
                    (item) => item.category === "lounge chair"
                );

                setProductsData(filteredProducts);
            }
            if(filterValue === "coffee tables"){
                const filteredProducts = products.filter(
                    (item) => item.category === "coffee tables"
                );

                setProductsData(filteredProducts);
            }
            if(filterValue === "conference table"){
                const filteredProducts = products.filter(
                    (item) => item.category === "conference table"
                );

                setProductsData(filteredProducts);
            }
            if(filterValue === "massaging chair"){
                const filteredProducts = products.filter(
                    (item) => item.category === "massaging chair"
                );

                setProductsData(filteredProducts);
            }
    };

    const handleSearch = (e) => {
        const searchTerm = e.target.value

        const searchedProducts = products.filter(item => item.productName.toLowerCase().includes(searchTerm.toLowerCase()))
        setProductsData(searchedProducts)
    }

    return <Helmet title="shop">
        <CommonSection title="Products"/>
        <section>
            <Container>
                <Row>
                    <Col lg='3' md='6'>
                        <div className="filter__widget">
                            <select onChange={handleFilter} value={selectedCategory}>
                            <option>Filter By Category</option>
                                <option value="sofas">Sofas</option>
                                <option value="executive table">Executive Table</option>
                                <option value="managers table">Managers Table</option>
                                <option value="office chair">Office Chairs</option>
                                <option value="workstation">Workstation</option>
                                <option value="reception table">Reception Table</option>
                                <option value="waiting chair">Waiting Chair</option>
                                <option value="bookshelves">Bookshelves </option>
                                <option value="credenza">Credenza </option>
                                <option value="lounge chair">Lounge Chair</option>
                                <option value="coffee tables">Coffee Tables </option>
                                <option value="conference table">Conference Table</option>
                                <option value="massaging chair">Massaging Chair </option>
                            </select>
                        </div>
                    </Col>
                    <Col lg='3' md='6' className='text-end'>
                    <div className="filter__widget">
                            <select>
                            <option>Sort By</option>
                                <option value="ascending">Ascending</option>
                                <option value="descending">Descending</option>
                            </select>
                        </div>
                    </Col>
                    <Col lg='6' md='12'>
                        <div className="search__box">
                            <input type="text" placeholder='Search........' onChange={handleSearch}/>
                            <span><i class="ri-search-line"></i></span>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        <section className='pt-0'>
            <Container>
                <Row>
                    {
                        productsData.length === 0? (
                            <h1 className='text-center fs-4 text-white'>Select a Category above </h1>
                        ) : (
                            <ProductsList data={productsData}/>
                        )
                    }
                </Row>
            </Container>
        </section>
    </Helmet>
};
export default Shop;