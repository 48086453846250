import React from 'react';
import { Container,Row,Col } from 'reactstrap';
import {motion} from 'framer-motion';

import './Services.css';
import serviceData from '../assets/data/serviceData';

const Services = () => {
  return <section className="Services" data-aos="fade-right">
    <Container>
        <Row>
        {
            serviceData.map((item, index)=>(
                <Col lg='4' md='4' key={index}>
                <motion.div whileHover={{ scale:1.1 }} className="service__item" style={{background:`${item.bg}`}}>
                    <span><i class={item.icon} style={{border:`${item.bd}`}}></i></span>
                    <div>
                        <h3 style={{color:`${item.cl}`}}>{item.title}</h3>
                        <p style={{color:`${item.cl}`}}>{item.subtitle}</p>
                    </div>
                </motion.div>
            </Col>
            ))
        }
        </Row>
    </Container>
  </section>
}

export default Services