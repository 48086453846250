const serviceData = [
  {
    icon: "ri-truck-line",
    title: "Reliable Shipping",
    subtitle: " on all orders.",
    bg: "#000",
    bd: "1px solid #f9d342",
    cl:"#f9d342",
  },
  // {
  //   icon: "ri-refresh-line",
  //   title: "30 day Returns",
  //   subtitle: "refund guarantee.",
  //   bg: "#f9d342",
  // },
  {
    icon: "ri-secure-payment-line",
    title: "WholeSale Price",
    subtitle: "guarantee.",
    bg: "#f9d342",
    bd: "1px solid #f9d342",
    cl:"#000",
  },
  {
    icon: "ri-exchange-dollar-line",
    title: " Safe Shopping",
    subtitle: "guarantee.",
    bg: "#000",
    cl:"#f9d342",

  },
];

export default serviceData;
