
import React, {useState} from 'react';
import Helmet from '../components/Helmet/Helmet';
import { Container, Row, Col, Form,FormGroup } from 'reactstrap';
import { Link } from 'react-router-dom';

import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { doc,setDoc } from 'firebase/firestore';

import {auth} from "../firebase.config";
import { storage } from '../firebase.config';
import { db } from '../firebase.config';

import { toast } from 'react-toastify';

import '../styles/login.css';
import { useNavigate } from 'react-router-dom';

const Signup = () => {

    const [username, setUsername] = useState("");
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const scrollToTop = () => {
        window.scrollTo({
          top: 1,
          behavior: "smooth"
        });
      };

const navigate = useNavigate()

const signup = async(e)=>{
    e.preventDefault();
    setLoading(true);

    try{
        const userCredential = await createUserWithEmailAndPassword(auth,email,password);

        const user = userCredential.user;

        let downloadURL = null;
        if (file) {
            const storageRef = ref(storage, `images/${Date.now() + username}`)
            const uploadTask = uploadBytesResumable(storageRef, file)

            uploadTask.on((error)=>{
                toast.error(error.message)
            },()=>{
                getDownloadURL(uploadTask.snapshot.ref).then(async(url)=>{
                    downloadURL = url;

                    // update user profile
                    await updateProfile(user,{
                        displayName:username,
                        photoURL: downloadURL,
                    });

                    // store user data in firestore database
                    try {
                        await setDoc(doc(db, "users", user.uid), {
                          uid: user.uid,
                          firstName: firstname,
                          lastName: lastname,
                          displayName: username,
                          email,
                          photoURL: downloadURL,
                        });
                      } catch (error) {
                        console.error(error);
                        toast.error("Error creating user doc: " + error.message);
                      }

                    setLoading(false)
                    toast.success("Account created")
                    navigate('/login')
                });
            });
        } else {
            // update user profile
            await updateProfile(user,{
                displayName:username,
                photoURL: downloadURL,
            });

            // store user data in firestore database
            try {
                await setDoc(doc(db, "users", user.uid), {
                  uid: user.uid,
                  firstName: firstname,
                  lastName: lastname,
                  displayName: username,
                  email,
                  photoURL: null,
                });
              } catch (error) {
                console.error(error);
                toast.error("Error creating user doc: " + error.message);
              }

            setLoading(false)
            toast.success("Account created")
            navigate('/login')
        }
    } catch (error){
        setLoading(false)
        toast.error("Something went wrong");
    }
};

    return <Helmet title = "Signup">
        <section>
            <Container>
                <Row>
                {
                    loading?( <Col lg='12' className='text-center'><h5 className='fw-bold text-white'>Loading.....</h5></Col>):(
                    <Col lg='6' className='m-auto text-center'>
                        <h3 className='fw-bold mb-4 text-white'>Signup</h3>

                        <Form className='auth__form' onSubmit={signup}>
                            <FormGroup className='form__group'>
                            <input type="text" placeholder='Enter First Name' value={firstname} onChange={(e)=>setFirstname(e.target.value)}/>
                            </FormGroup>
                            <FormGroup className='form__group'>
                            <input type="text" placeholder='Enter Last Name' value={lastname} onChange={(e)=>setLastname(e.target.value)}/>
                            </FormGroup>
                            <FormGroup className='form__group'>
                            <input type="text" placeholder='Enter Username' value={username} onChange={(e)=>setUsername(e.target.value)}/>
                            </FormGroup>
                            <FormGroup className='form__group'>
                            <input type="email" placeholder='Enter your email' value={email} onChange={(e)=>setEmail(e.target.value)}/>
                            </FormGroup>
                            <FormGroup className='form__group'>
                            <input type="password" placeholder='Enter your password' value={password} onChange={(e)=>setPassword(e.target.value)} />
                            </FormGroup>
                            <FormGroup className='form__group'>
                            <input type="file" onChange={(e)=>setFile(e.target.files[0])} />
                            </FormGroup>
                            <button type='submit' className="buy__btn auth__btn">Create an Account
                            </button>
                            <p>Already have an account yet? <Link to='/login' onClick={scrollToTop}>Login</Link></p>
                        </Form>
                    </Col>
                    )
                }
                </Row>
            </Container>
        </section>
    </Helmet>
};

export default Signup