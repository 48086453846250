import React from 'react';
import { Container,Row } from 'reactstrap';
import logo from '../assets/images/logo.jpg'

import useAuth from '../custom-hooks/useAuth';
import '../styles/admin-nav.css';
import { NavLink } from 'react-router-dom';
const admin__nav = [
    {
        display:'Dashboard',
        path:'/dashadmin'
    },
    {
        display:'Add Products',
        path:'/dashadmin/add-product'
    },
    {
        display:'All-Products',
        path:'/dashadmin/all-products'
    },
    {
        display:'Users',
        path:'/dashadmin/users'
    },
]
const AdminNav = () => {
    const {currentUser} = useAuth();
  return (
    <>
  <header className='admin__header'>
    <div className="admin__nav-top">
        <Container>
            <div className="admin__nav-wrapper-top">
                <div className="logo">
                    <img src={logo} alt="logo"/>  
                    <h2>League News Interlink</h2>
                </div>
                <div className="admin__nav-top-right">
                    <span><i className="ri-notification-3-line"></i></span>
                    <span><i className="ri-settings-2-line"></i></span>
                    <img src={currentUser && currentUser.photoURL} alt="" />
                </div>
            </div>
        </Container>
    </div>
  </header>
  <section className="admin__menu p-0">
    <Container>
        <Row>
            <div className="admin__navigation">
                <ul className="admin__menu-list">
                    {
                        admin__nav.map((item,index)=>(
                            <li className="admin__menu-item" key={index}>
                               <NavLink to={item.path} className={navClass => navClass.isActive ? 'active__admin-menu' : ''}>{item.display}</NavLink>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </Row>
    </Container>
  </section>
  </>
  );
};

export default AdminNav