import React, { useState, useRef, useEffect } from 'react';
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';
import { Container, Row,Col } from 'reactstrap';
import {useParams} from 'react-router-dom';
import Helmet from '../components/Helmet/Helmet';
import CommonSection from '../components/UI/CommonSection';
import '../styles/product-details.css';
import useAuth from '../custom-hooks/useAuth';
import {motion} from 'framer-motion';
import { useDispatch } from 'react-redux/es/exports';
import { cartActions } from '../redux/slices/cartSlice';
import { toast } from 'react-toastify';

import {db} from '../firebase.config';
import { doc, getDoc } from 'firebase/firestore';

const ProductDetails = () => {
    const [product,setProduct] = useState({});
    const [tab, setTab] = useState("desc");
    const reviewUser = useRef('');
    const reviewMsg = useRef('');
    const dispatch = useDispatch()
    const { currentUser } = useAuth();
    const navigate = useNavigate()
    
    const [rating,setRating] = useState(null);
    const {id} = useParams();
    const docRef = doc(db, 'products',id)

    useEffect(()=>{
        const getProduct = async()=>{
        const docSnap = await getDoc(docRef)
        if(docSnap.exists()){
            setProduct(docSnap.data())
        }else{
            console.log('no product!')
        }
        }
        getProduct();
    },[]);
    const {
        imgUrl,
        productName,
        price, 
        description, 
        shortDesc,
        colors, 
        category
    } = product;

    const submitHandler = (e)=>{
        e.preventDefault();

        const reviewUserName = reviewUser.current.value;
        const reviewUserMsg = reviewMsg.current.value;

        const reviewObj = {
            UserName: reviewUserName,
            text: reviewUserMsg,
            rating,
        };
        console.log(reviewObj);
        toast.success("Review Submitted");
    };
    const addToCart = ()=>{
    if (currentUser) {
    dispatch(cartActions.addItem({
        id,
        productName,
        shortDesc,
        price,
        imgUrl: imgUrl,
        quantity: 1,
    })
    );

    toast.success('Product Added Successfully');
}else {
    window.scrollTo(0, 0);
    // Redirect user to sign up page
    navigate('/signup');
}
    const cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    const existingItemIndex = cartItems.findIndex(cartItem => cartItem.id === id);
    if (existingItemIndex >= 0) {
    cartItems[existingItemIndex].quantity += 1;
  } else {
    cartItems.push({
      id: id,
      productName: productName,
      shortDesc:shortDesc,
      price: price,
      imgUrl: imgUrl,
      quantity: 1
    });
}
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
};

const request = () => {
    dispatch(
        cartActions.addItem({
            id,
            image: imgUrl,
            shortDesc,
            productName,
            price,
        })
    );
const message = `Hi, I would like to purchase ${productName} (model number: ${shortDesc}).`;
    const url = `https://wa.me/2349020849300?text=${encodeURIComponent(message)}`;
    window.open(url);};
    useEffect(()=>{
        window.scrollTo(0,0)
    },[product]);

    const images = [imgUrl, product.img2Url, product.img3Url].filter(url => url !== undefined && url !== null);
    const sliderDisabled = images.length <= 1;
    
    return <Helmet title={productName}>
        <CommonSection title={productName}/>
            <section className='pt-0'>
                <Container>
                    <Row>
                    <Col lg="4" className="mt-4">
                    {sliderDisabled ? (
                            <img src={imgUrl} alt="" />
                        ) : (
                            <Slider dots={true} infinite={true} slidesToShow={1} slidesToScroll={1}>
                                {images.map((url, index) => (
                                    <div key={index}>
                                        <img src={url} alt="" />
                                    </div>
                                ))}
                            </Slider>
                        )}
                    </Col>

                        <Col lg="6">
                            <div className="product__details">
                                <h2 className='mb-3'>{productName}</h2>
                                <div className="product__rating d-flex align-items-center gap-5 mb-3">
                                    <div>
                                        <span><i class="ri-star-s-fill"></i></span>
                                        <span><i class="ri-star-s-fill"></i></span>
                                        <span><i class="ri-star-s-fill"></i></span>
                                        <span><i class="ri-star-s-fill"></i></span>
                                        <span><i class="ri-star-half-s-line"></i></span>
                                    </div>
                                </div>

                                <div className='d-flex align-items-center gap-5'>
                                </div>
                                <p className='mt-3'style={{color:"#f9d342"}}>Model Number: {shortDesc}</p>
                                <p className='mt-3'style={{color:"aliceblue"}}>Available colors: {colors}</p>
                                <div>
                                <motion.button whileTap={{scale:1.2}} className="buy__btn w-100" onClick={addToCart}>Add to Cart</motion.button>
                                <motion.button whileTap={{scale:1.2}} className="buy__btn w-100 mt-3" onClick={request}>Request for Price</motion.button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container>
                    <Row>
                        <Col lg='12'>
                            <div className="tab__wrapper d-flex align-items-center gap-5">
                                <h6 className={`${tab === "desc" ? "active__tab" : ""}`} onClick={()=> setTab("desc")}>Description</h6>
                                <h6 className={`${tab === "rev" ? "active__tab" : ""}`} onClick={()=> setTab("rev")}>
                                Reviews
                                </h6>
                            </div>
                            {
                                tab === "desc" ? (
                                    <div className="tab__content mt-5">
                                    <p>{description}</p>
                                </div>
                                ) : (
                                    <div className='product__review mt-5'>
                                        <div className="review__wrapper">
                                            <div className="review__form">
                                            <h4>Leave your experience</h4>
                                                <form action="" onSubmit={submitHandler}>
                                                    <div className="form__group">
                                                        <input type="text" placeholder='Enter name' ref={reviewUser} required/>
                                                    </div>

                                                <div className="form__group d-flex align-items-center gap-5 rating__group">
                                                    <motion.span whileTap={{scale:1.2}} onClick={()=> setRating(1)}>1<i class="ri-star-s-fill"></i></motion.span>
                                                    <motion.span whileTap={{scale:1.2}} onClick={()=> setRating(2)}>2<i class="ri-star-s-fill"></i></motion.span>
                                                    <motion.span whileTap={{scale:1.2}} onClick={()=> setRating(3)}>3<i class="ri-star-s-fill"></i></motion.span>
                                                    <motion.span whileTap={{scale:1.2}} onClick={()=> setRating(4)}>4<i class="ri-star-s-fill"></i></motion.span>
                                                    <motion.span whileTap={{scale:1.2}} onClick={()=> setRating(5)}>5<i class="ri-star-s-fill"></i></motion.span>
                                                </div>
                                                <div className="form__group">
                                                    <textarea ref={reviewMsg} rows={4} type="text" placeholder='Review Message...' required/>
                                                </div>
                                                <motion.button whileTap={{scale:1.2}} type='submit' className='buy__btn'>Submit</motion.button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Col>
                    </Row>
                </Container>
            </section>
    </Helmet>
};

export default ProductDetails;