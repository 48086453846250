import { useState } from 'react';
import { auth } from '../firebase.config';
import { Container, Row, Col, Form, FormGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { Link } from 'react-router-dom';
import '../styles/login.css';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [resetEmail, setResetEmail] = useState('');

  const handleResetPassword = (e) => {
    e.preventDefault();
    auth
      .sendPasswordResetEmail(email)
      .then(() => {
        toast.success('Password reset email sent!');
      })
      .catch((error) => {
        toast.error('Failed to send password reset email');
      });
  };

  return (
    <Container>
      <Row>
        <Col lg='6' className='m-auto text-center'>
          <h3 className='fw-bold mt-4 mb-4 text-white'>Forgot Password? </h3>
          <Form onSubmit={handleResetPassword}>
            <FormGroup className='form__group'>
                <input type="email" placeholder='Enter your email for password reset' value={resetEmail} onChange={(e)=>setResetEmail(e.target.value)} />
            </FormGroup>
            <button type='button' className="buy__btn auth__btn mb-4" onClick={() => { sendPasswordResetEmail(auth, resetEmail); toast.success("Password reset link sent") }}>Submit</button>
            <p className='mb-4'>Go back to Login? <Link to='/login'>Click here!</Link></p>

          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default ForgotPassword;
