import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../services/Slide.css';
import { Link } from "react-router-dom";

import category2 from '../assets/images/category2.jpg'
import category3 from '../assets/images/category3.jpg'
import category4 from '../assets/images/category4.jpg'
import category5 from '../assets/images/category5.jpg'
import category6 from '../assets/images/category6.jpg'
import category7 from '../assets/images/category7.jpg'
import category8 from '../assets/images/category8.jpg'
import category9 from '../assets/images/category9.jpg'
import category10 from '../assets/images/category10.jpg'
import category11 from '../assets/images/category11.jpg'
import category12 from '../assets/images/category12.jpg'
import category13 from '../assets/images/category13.jpg'

const data = [
  {
    category: "Executive Table",
    image: category5,
    path:"/executivetable",
  },
  {
    category: "Lounge Chair",
    image: category2,
    path:"/loungechair"
  },
  {
    category: "Office Chair",
    image: category3,
    path: "/officechair",
  },
  {
    category: "Sofa",
    image: category4,
    path: "/sofa",
  },
  {
    category: "Bookshelf",
    image: category6,
    path:"/bookshelf"
  },
  {
    category: "Credenza",
    image: category7,
    path:"/credenza",
  },
  {
    category: "Conference Table",
    image: category8,
    path:"/conferencetable",
  },
  {
    category: "WorkStation",
    image: category9,
    path: "/workstation",
  },
  {
    category: "Manager Table",
    image: category10,
    path: "/managertable",
  },
  {
    category: "Coffee Table",
    image: category11,
    path: "/coffeetable",
  },
  {
    category: "Waiting Chair",
    image: category12,
    path:"/waitingchair",
  },
  {
    category: "Reception Table",
    image: category13,
    path: "/receptiontable"
  },
];

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 2,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 650,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 300,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 2,
      },
    },
  ],
};
const Slide = () => {
 return (
    <div className="carousel-wrapper mt-2 mb-2">
      <Slider {...settings}>
        {data.map((item) => (
          <div className="card my-2" key={item.category}>
            <Link to={item.path || ""}>
              <img src={item.image} alt={item.category} />
              <div className="card-content">
                <h4>{item.category}</h4>
              </div>
            </Link>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Slide;
