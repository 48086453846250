import React from 'react';
import './footer.css'
import logo from '../../assets/images/logo.jpg'
import { Container, Row , Col, ListGroup, ListGroupItem } from 'reactstrap';
import { Link } from 'react-router-dom';


const Footer = () => {

    const year = new Date().getFullYear()
    return (
    <footer className="footer">
        <Container>
            <Row>
                <Col lg='4' className='mb-4' md='6'>
                    <div className="footer__quick-links">
                        <h4 className="quick__links-title">Top Categories</h4>
                        <ListGroup>
                            <ListGroupItem className='ps-0 border-0'>
                                <Link to="#">High Quality Office Furniture</Link>
                            </ListGroupItem>
                            <ListGroupItem className='ps-0 border-0'>
                                <Link to="#">Nationwide Delivery </Link>
                            </ListGroupItem>
                            <ListGroupItem className='ps-0 border-0'>
                                <Link to="#">Excellent Customer Service</Link>
                            </ListGroupItem>
                            <ListGroupItem className='ps-0 border-0'>
                                <Link to="#">WholeSale price</Link>
                            </ListGroupItem>
                        </ListGroup>
                        <div className="hero__img me-6">
                            <img src={logo} alt=""/>
                        </div>
                    </div>
                </Col>
                <Col lg='3' className='mb-4' md='6'>
                    <div className="footer__quick-links">
                        <h4 className="quick__links-title">Top Categories</h4>
                        <ListGroup>
                            <ListGroupItem className='ps-0 border-0'>
                                <Link to="/executivetable">Executive table</Link>
                            </ListGroupItem>
                            <ListGroupItem className='ps-0 border-0'>
                                <Link to="/sofa">Sofas </Link>
                            </ListGroupItem>
                            <ListGroupItem className='ps-0 border-0'>
                                <Link to="/workstation">Workstation</Link>
                            </ListGroupItem>
                            <ListGroupItem className='ps-0 border-0'>
                                <Link to="/officechair">Office chairs</Link>
                            </ListGroupItem>
                            <ListGroupItem className='ps-0 border-0'>
                                <Link to="/bookshelf">Bookshelves </Link>
                            </ListGroupItem>
                            <ListGroupItem className='ps-0 border-0'>
                                <Link to="/credenza">Credenza</Link>
                            </ListGroupItem>
                            <ListGroupItem className='ps-0 border-0'>
                                <Link to="/loungechair">Lounge chair</Link>
                            </ListGroupItem>
                            <ListGroupItem className='ps-0 border-0'>
                                <Link to="/conferencetable">Conference table</Link>
                            </ListGroupItem>
                            <ListGroupItem className='ps-0 border-0'>
                                <Link to="#">Massaging chair</Link>
                            </ListGroupItem>
                        </ListGroup>
                    </div>
                </Col>
                <Col lg='2' md='3' className='mb-4'>
                <div className="footer__quick-links">
                        <h4 className="quick__links-title">Useful Links</h4>
                        <ListGroup>
                            <ListGroupItem className='ps-0 border-0'>
                                <Link to="/shop">Shop</Link>
                            </ListGroupItem>

                            <ListGroupItem className='ps-0 border-0'>
                                <Link to="/cart">Cart</Link>
                            </ListGroupItem>

                            <ListGroupItem className='ps-0 border-0'>
                                <Link to="/login">Login</Link>
                            </ListGroupItem>

                            <ListGroupItem className='ps-0 border-0'>
                                <Link to="#">Privacy Policy</Link>
                            </ListGroupItem>
                        </ListGroup>
                    </div>

                </Col>
                <Col lg='3' md='4'>
                <div className="footer__quick-links">
                        <h4 className="quick__links-title">Contact</h4>
                        <ListGroup className='footer__contact'>
                            <ListGroupItem className='ps-0 border-0 d-flex align-items-center gap-2'>
                                <span><i class="ri-map-pin-line"></i></span>
                                <p> No 18, Nepa Road off Alaba International Market Road by Corner bustop, Ojo, Lagos.</p>
                            </ListGroupItem>

                            <ListGroupItem className='ps-0 border-0 d-flex align-items-center gap-2'>
                                <span><i class="ri-phone-line"></i></span>
                                <a href="tel:+2349020849300"><p>+234 9020849300</p></a>
                            </ListGroupItem>

                            <ListGroupItem className='ps-0 border-0 d-flex align-items-center gap-2'>
                                <span><i class="ri-mail-line"></i></span>
                                <a href="mailto:goodnewsinterlink@gmail.com"><p> goodnewsinterlink@gmail.com</p></a>
                            </ListGroupItem>
                            <ListGroupItem className='ps-0 border-0 d-flex align-items-center gap-2'>
                                <span><i class="ri-mail-line"></i></span>
                                <a href="mailto:goodnewsinterlinkltd@yahoo.com"><p> goodnewsinterlinkltd@yahoo.com</p></a>
                            </ListGroupItem>
                            <ListGroupItem className='ps-0 border-0 d-flex align-items-center gap-2'>
                                <span><i class="ri-instagram-line"></i></span>
                                <a href="https://www.instagram.com/leaguenewsfurniture"><p>leaguenewsfurniture</p></a>
                            </ListGroupItem>
                            <ListGroupItem className='ps-0 border-0 d-flex align-items-center gap-2'>
                                <span><i class="ri-facebook-line"></i></span>
                                <a href="https://www.facebook.com/leaguenewsinterlinkltd?mibextid=ZbWKwL" target="_blank"><p> leaguenewsfurniture</p></a>
                            </ListGroupItem>
                        </ListGroup>
                    </div>
                </Col>
                <Col lg='12' className='mb-4'>
                    <p className="footer__copyright">Copyright {year} developed by Chelsea Ajah || All rights Reserved.</p>
                </Col>
            </Row>
        </Container>
    </footer>
    );
};

export default Footer