import React from 'react';
import {motion} from 'framer-motion';
import '../../styles/product-card.css';
import { Col } from 'reactstrap';
import {Link, useNavigate} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { cartActions } from '../../redux/slices/cartSlice';
import useAuth from '../../custom-hooks/useAuth'
import {toast } from 'react-toastify';
const ProductCard = ({item}) => {

const dispatch = useDispatch();
const { currentUser } = useAuth();
const navigate = useNavigate()

const addToCart = ()=>{
    if (currentUser) {
      dispatch(cartActions.addItem({
          id:item.id,
          productName: item.productName,
          shortDesc:item.shortDesc,
          price: item.price,
          imgUrl: item.imgUrl,
          quantity: 1,
      }));
    toast.success('Product Added Successfully');
} else {
    window.scrollTo(0, 0);
    // Redirect user to sign up page
    navigate('/signup');
}
    const cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    const existingItemIndex = cartItems.findIndex(cartItem => cartItem.id === item.id);
    if (existingItemIndex >= 0) {
    cartItems[existingItemIndex].quantity += 1;
  } else {
    cartItems.push({
      id: item.id,
      productName: item.productName,
      shortDesc:item.shortDesc,
      price: item.price,
      imgUrl: item.imgUrl,
      quantity: 1
    });
}
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
};

return (
<Col lg='4' md='6' xs='10 mx-auto' className='mb-2'>
    <div className="product__item" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
        <div className="containerr">
            <div className="product__img">
                <Link to={`/shop/${item.id}`}>
                    <motion.img whileHover={{ scale:0.9 }} src={item.imgUrl} alt="" style={{ width: "100% !important", height: "200px", objectFit: "contain" }}/>
                </Link>
            </div>
        </div>
        <div className='p-2 product__info'>
            <h3 className="product__name">
                <Link to={`/shop/${item.id}`}>{item.productName}</Link>
            </h3>
            <span style={{color:"aliceblue"}}>Model Number: {item.shortDesc}</span>
        </div>
        <div className="product__card-bottom d-flex align-items-center justify-content-center p-2">
            <motion.span whileTap={{ scale:1.2 }} onClick={addToCart}><i class="ri-add-line"></i></motion.span>
        </div>
    </div>
</Col>
  );
};

export default ProductCard;