import React, {useState, useEffect} from 'react';
import Helmet from '../components/Helmet/Helmet';
import CommonSection from '../components/UI/CommonSection';
import ProductsList from '../components/UI/ProductsList';
import useGetData from '../custom-hooks/useGetData';
import { Container, Row , Col } from 'reactstrap';
import AOS from 'aos';
import 'aos/dist/aos.css'

const ExecutiveTable = () => {
    const {data: products,loading} = useGetData('products'); 
    const [executiveTableProducts, setExecutiveTableProducts] = useState([]);

    useEffect(()=>{
        AOS.init();

        const filteredExecutiveTableProducts = products.filter(
            (item)=> item.category ==="executive table"
            );
            setExecutiveTableProducts(filteredExecutiveTableProducts);

        }, [products]);

return(
    <Helmet title={"Executive Tables"}>
    <CommonSection title='Executive Tables'/>

    <section className="trending__products" id='Executive Table'>
                <Container>
                    <Row>
                        {
                            loading ? <div class="spinner-grow text-warning" role="status">
                                <span class="visually-hidden">Loading...</span>
                                </div> : <ProductsList data={executiveTableProducts}/>
                        }
                    </Row>
                </Container>
            </section>

</Helmet>
  )

}

export default ExecutiveTable;