// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth} from 'firebase/auth';
import { getFirestore } from "firebase/firestore";
import {getStorage} from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyDaAWq58jv5KQM-m8Sv64COFT6EDl0aaBg",
  authDomain: "league-news-interlink.firebaseapp.com",
  projectId: "league-news-interlink",
  storageBucket: "league-news-interlink.appspot.com",
  messagingSenderId: "1008464110261",
  appId: "1:1008464110261:web:aed756a67c81e32b3e14fc"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
