import React,{useState} from 'react';
import { Form, FormGroup, Container,Row,Col } from 'reactstrap';
import { toast } from 'react-toastify';

import {db,storage} from '../firebase.config';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { collection, addDoc } from 'firebase/firestore';
import {useNavigate} from 'react-router-dom';

const AddProducts = () => {

  const [enterTitle, setEnterTitle] = useState('');
  const [enterShortDesc, setEnterShortDesc] = useState('');
  const [enterDescription, setEnterDescription] = useState('');
  const [enterColors, setEnterColors] = useState('');
  const [enterCategory, setEnterCategory] = useState('');
  const [enterProductImg, setEnterProductImg] = useState(null);

  const [enterImage2, setEnterImage2] = useState(null);
  const [enterImage3, setEnterImage3] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const addProduct = async(e)=>{
    e.preventDefault()
    setLoading(true)

    // add product to the firebase database //
    try{
      const docRef = await collection(db, 'products');

      const storageRef = ref(storage, `productImages/${Date.now() + enterProductImg.name}`);
      const uploadTask = uploadBytesResumable(storageRef, enterProductImg);


      const downloadURLs = {};

      if (enterImage2) {
        const storageRef2 = ref(storage, `productImages/${Date.now() + enterImage2.name}`);
        const uploadTask2 = uploadBytesResumable(storageRef2, enterImage2);
        await uploadTask2;
        downloadURLs.img2Url = await getDownloadURL(uploadTask2.snapshot.ref);
      }

      if (enterImage3) {
        const storageRef3 = ref(storage, `productImages/${Date.now() + enterImage3.name}`);
        const uploadTask3 = uploadBytesResumable(storageRef3, enterImage3);
        await uploadTask3;
        downloadURLs.img3Url = await getDownloadURL(uploadTask3.snapshot.ref);
      }

      await uploadTask;
      const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
      await addDoc(docRef,  {
        productName: enterTitle,
        shortDesc: enterShortDesc,
        colors: enterColors,
        description: enterDescription,
        category: enterCategory,
        imgUrl: downloadURL,
        ...downloadURLs,
      });

      setLoading(false)
      toast.success('product successfully added');
      navigate("/dashadmin/all-products");
    } catch (err) {
      setLoading(false);
      toast.error('product not added!');
    }
  };

  return(
     <section>
    <Container>
      <Row>
        <Col lg='12'>
        {
          loading ? <h4 className='py-5 '>Loading.....</h4> : <>
          <h4 className='mb-5 text-white'>Add Product</h4>
          <Form onSubmit={addProduct}>
            <FormGroup className="form__group">
              <span style={{color:"#f9d342"}}>Product Title</span>
              <input type="text" placeholder='Credenza' value={enterTitle} onChange={e => setEnterTitle(e.target.value)} required/>
            </FormGroup>
            <FormGroup className="form__group">
              <span style={{color:"#f9d342"}}>Model Number</span>
              <input type="text" placeholder='Enter Model Number' value={enterShortDesc} onChange={e => setEnterShortDesc(e.target.value)} required/>
            </FormGroup>
            <FormGroup className="form__group">
              <span style={{color:"#f9d342"}}>Available Colours</span>
              <input type="text" placeholder='Enter a color' value={enterColors} onChange={e => setEnterColors(e.target.value)}/>
            </FormGroup>
            <FormGroup className="form__group">
              <span style={{color:"#f9d342"}}>Description</span>
              <textarea cols="10" rows="10" type="text" placeholder='Description.....' value={enterDescription} onChange={e => setEnterDescription(e.target.value)} required/>
            </FormGroup>

            <div className='d-flex align-items-center justify-content-between gap-5'>
            <FormGroup className="form__group w-50">
              <span style={{color:"#f9d342"}}>Category</span>
              <select className='w-100 p-2' value={enterCategory} onChange={e => setEnterCategory(e.target.value)}>
                <option>Select Category</option>
                <option value="sofas">Sofas</option>
                  <option value="executive table">Executive Table</option>
                  <option value="managers table">Managers Table</option>
                  <option value="office chair">Office Chair</option>
                  <option value="workstation">Workstation</option>
                  <option value="reception table">Reception Table</option>
                  <option value="waiting chair">Waiting Chair</option>
                  <option value="bookshelves">Bookshelves </option>
                  <option value="credenza">Credenza </option>
                  <option value="lounge chair">Lounge Chair</option>
                  <option value="coffee tables">Coffee Tables </option>
                  <option value="conference table">Conference Table </option>
                  <option value="massaging chair">Massaging Chair </option>
              </select>
            </FormGroup>
            <FormGroup className="form__group w-50">
                  <span style={{color:"#f9d342"}}>Product Image</span>
                  <input type="file"  style={{color:"#fff"}} onChange={e=> setEnterProductImg(e.target.files[0])} required/>
            </FormGroup>
            </div>
              <div className='d-flex align-items-center justify-content-between gap-5'>
              <FormGroup className="form__group w-50">
                    <span style={{ color: "#f9d342" }}>Product Image 2</span>
                    <input
                      type="file"
                      style={{ color: "#fff" }}
                      onChange={(e) => setEnterImage2(e.target.files[0])}
                    />
                  </FormGroup>
                  <FormGroup className="form__group w-50">
                    <span style={{ color: "#f9d342" }}>Product Image 3</span>
                    <input
                      type="file"
                      style={{ color: "#fff" }}
                      onChange={(e) => setEnterImage3(e.target.files[0])}
                    />
                  </FormGroup>
                  </div>
              <button className=" buy__btn" type='submit'>Add Product</button>
          </Form>
          </>
        }
        </Col>
      </Row>
    </Container>
  </section>
  );
};

export default AddProducts