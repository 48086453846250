import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { db } from '../firebase.config';
import { doc, deleteDoc, updateDoc } from 'firebase/firestore';
import useGetData from '../custom-hooks/useGetData';
import { toast } from 'react-toastify';

const AllProducts = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const { data: productsData, loading } = useGetData('products');
  
  const deleteProduct = async (id) => {
    await deleteDoc(doc(db, 'products', id));
    toast.success('Deleted');
  };

  const editProduct = async (id, updatedProduct) => {
    await updateDoc(doc(db, 'products', id), updatedProduct);
    toast.success('Updated');
  };

  const filteredProducts = productsData.filter((product) =>
    product.productName.toLowerCase().includes(searchTerm.toLowerCase())
  );
  return (
    <section>
      <Container>
        <Row>
          <Col lg='12'>
            <div className='search__box d-flex mb-3'>
              <input
                type='text'
                className='form-control me-2'
                placeholder='Search products...'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <table className='table'>
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Title</th>
                  <th>Model Number</th>
                  <th>Category</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <div class='spinner-grow text-warning' role='status'>
                    <span class='visually-hidden'>Loading...</span>
                  </div>
                ) : (
                  filteredProducts.map((item) => (
                    <tr key={item.id}>
                      <td>
                        <img src={item.imgUrl} alt='' />
                      </td>
                      <td>{item.productName}</td>
                      <td>{item.shortDesc}</td>
                      <td>{item.category}</td>
                      <td>
                        <button
                          onClick={() => {
                            deleteProduct(item.id);
                          }}
                          className='btn btn-danger'
                        >
                          Delete
                        </button>
                        <button
                          onClick={() => {
                            const newProductName = prompt('Enter new product name:', item.productName);
                            const newShortDesc = prompt('Enter new model number:', item.shortDesc);
                            const newColors = prompt('Enter new colors:', item.colors);
                            const newDescription = prompt('Enter new description:', item.description);
                            const newCategory = prompt('Enter new category:', item.category);
                            if (newProductName && newShortDesc && newCategory && newDescription && newColors) {
                              editProduct(item.id, {
                                productName: newProductName,
                                shortDesc: newShortDesc,
                                category: newCategory,
                                description: newDescription,
                              });
                            }
                          }}
                          className='btn btn-primary ms-2'
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AllProducts;
