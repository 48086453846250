import React from 'react';
import heroImg from '../assets/images/Picture1.png';

import { Container, Row , Col, ListGroup, ListGroupItem } from 'reactstrap';
import Helmet from '../components/Helmet/Helmet';
import CommonSection from '../components/UI/CommonSection';


const About = () => {
  return (
    <Helmet title={"About"}>
    <CommonSection title='About Us'/>

    <section className="hero__section">
            <Container>
                <Row>
                    <Col lg='4' md='4'>
                        <div className="hero__img">
                            <h4 style={{color:"#fff"}}>SHOWROOM PICTURE</h4>
                            <img src={heroImg} alt="" />
                        </div>
                    </Col>
            <Col lg="4" md='6'>
                <div className="hero__img mt-3">
                        <h4 style={{color:"#fff"}}>League News Interlink</h4>
                    </div>
                <p className="footer__text mt-4" style={{color:"#fff"}}>
                    is a wholesales based company specializing in the design, manufacture and installation of Office furniture for your Office, Hotel, School, and churches. LEAGUE NEWS INTERLINK combines a synergy of over 15 years of experience within the industry giving us an unrivalled reputation of being able to offer you specially designed furniture solutions for your need.
                    From craftsman to customer care we take great care of you, every step of the way turning your dream into reality.                    </p>
                </Col>
                <Col lg='3' md='4'>
                <div className="footer__quick-links mt-3">
                        <h4 className="hero__img" style={{color:"#fff", display:"flex"}}>Contact Information</h4>
                        <ListGroup className='footer__contact'>
                            <ListGroupItem className='ps-0 border-0 d-flex align-items-center gap-2'>
                                <span><i class="ri-map-pin-line"></i></span>
                                <p style={{color:"#fff"}}> No 18, Nepa Road off Alaba International Market Road by Corner bustop, Ojo, Lagos.</p>
                            </ListGroupItem>

                            <ListGroupItem className='ps-0 border-0 d-flex align-items-center gap-2'>
                                <span><i class="ri-phone-line"></i></span>
                                <a href="tel:+2349020849300"><p style={{color:"#fff"}}>+234 9020849300</p></a>
                            </ListGroupItem>

                            <ListGroupItem className='ps-0 border-0 d-flex align-items-center gap-2'>
                                <span><i class="ri-mail-line"></i></span>
                                <a href="mailto:goodnewsinterlink@gmail.com"><p style={{color:"#fff"}}> goodnewsinterlink@gmail.com</p></a>
                            </ListGroupItem>
                            <ListGroupItem className='ps-0 border-0 d-flex align-items-center gap-2'>
                                <span><i class="ri-mail-line"></i></span>
                                <a href="mailto:goodnewsinterlinkltd@yahoo.com"><p style={{color:"#fff"}}> goodnewsinterlinkltd@yahoo.com</p></a>
                            </ListGroupItem>
                            <ListGroupItem className='ps-0 border-0 d-flex align-items-center gap-2'>
                                <span><i class="ri-instagram-line"></i></span>
                                <a href="https://www.instagram.com/leaguenewsfurniture"><p style={{color:"#fff"}}>leaguenewsfurniture</p></a>
                            </ListGroupItem>
                            <ListGroupItem className='ps-0 border-0 d-flex align-items-center gap-2'>
                                <span><i class="ri-facebook-line"></i></span>
                                <a href="https://www.facebook.com/leaguenewsinterlinkltd?mibextid=ZbWKwL" target="_blank"><p style={{color:"#fff"}}> leaguenewsfurniture</p></a>
                            </ListGroupItem>
                        </ListGroup>
                    </div>
                </Col>
                </Row>
            </Container>
        </section>
</Helmet>
  )
}

export default About