import React from 'react';
import { Navigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import useAuth from '../custom-hooks/useAuth';

const ProtectedRoute = () => {
  const { currentUser, isAdmin } = useAuth();

  return isAdmin() ? <Outlet /> : <Navigate to="/dashadmin" />;
};

export default ProtectedRoute;
