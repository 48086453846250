import React, {useState, useEffect} from 'react';
import Helmet from '../components/Helmet/Helmet';
import CommonSection from '../components/UI/CommonSection';
import ProductsList from '../components/UI/ProductsList';
import useGetData from '../custom-hooks/useGetData';
import { Container, Row , Col } from 'reactstrap';
import AOS from 'aos';
import 'aos/dist/aos.css'

const ReceptionTable = () => {
    const {data: products,loading} = useGetData('products'); 
    const [receptionTablesProducts, setReceptionTablesProducts] = useState([]);

    useEffect(()=>{
        AOS.init();

        const filteredReceptionTablesProducts = products.filter(
            (item)=> item.category ==="reception table"
        );
            setReceptionTablesProducts(filteredReceptionTablesProducts);

        }, [products]);

return(
    <Helmet title={"Reception Table"}>
    <CommonSection title='Reception Table'/>

    <section className="trending__products">
                <Container>
                    <Row>
                        {
                            loading ? <div class="spinner-grow text-warning" role="status">
                                <span class="visually-hidden">Loading...</span>
                                </div> : <ProductsList data={receptionTablesProducts}/>
                        }
                    </Row>
                </Container>
            </section>

</Helmet>
  )

}

export default ReceptionTable;