import { Routes, Route, Navigate } from "react-router-dom";

import React from "react";
import Home from '../pages/Home';
import Shop from '../pages/Shop';
import Cart from '../pages/Cart';
import About from '../pages/About';
import Sofa from '../pages/Sofa';
import ExecutiveTable from '../pages/ExecutiveTable'
import ReceptionTable from '../pages/ReceptionTable'
import ProductDetails from '../pages/ProductDetails';
import LoungeChair from '../pages/LoungeChair';
import OfficeChair from '../pages/OfficeChair';
import WaitingChair from '../pages/WaitingChair';
import BookShelf from '../pages/BookShelf'
import Credenza from '../pages/Credenza'
import ConferenceTable from '../pages/ConferenceTable'
import CoffeeTable from '../pages/CoffeeTable'
import ManagerTable from '../pages/ManagerTable'
import WorkStation from '../pages/WorkStation'
import ForgotPassword from "../pages/ForgotPassword";

import Login from '../pages/Login';
import Signup from '../pages/Signup';
import ProtectedRoute from "./ProtectedRoute";

import AllProducts from '../admin/AllProducts';
import AddProducts from '../admin/AddProducts';
import Dashboard from "../admin/Dashboard";
import Users from "../admin/Users";

const Routers = () => {
    return <Routes>
    <Route path = "/*" element={<Navigate to ='/home'/>} />
        <Route path = 'home' element = {<Home/>}/>
        <Route path = 'shop' element = {<Shop/>}/>
        <Route path = 'cart' element = {<Cart/>}/>
        <Route path = 'sofa' element = {<Sofa/>}/>
        <Route path = 'executivetable' element = {<ExecutiveTable/>}/>
        <Route path = 'receptiontable' element = {<ReceptionTable/>}/>
        <Route path = 'officechair' element = {<OfficeChair/>}/>
        <Route path = 'waitingchair' element = {<WaitingChair/>}/>
        <Route path = 'loungechair' element = {<LoungeChair/>}/>
        <Route path = 'coffeetable' element = {<CoffeeTable/>}/>
        <Route path = 'bookshelf' element = {<BookShelf/>}/>
        <Route path = 'credenza' element = {<Credenza/>}/>
        <Route path = 'conferencetable' element = {<ConferenceTable/>}/>
        <Route path = 'managerTable' element = {<ManagerTable/>}/>
        <Route path = 'workstation' element = {<WorkStation/>}/>
        <Route path="about" element={<About/>}/>
        <Route path = 'shop/:id' element = {<ProductDetails/>}/>

        <Route path = '/*' element = {<ProtectedRoute/>}>
            <Route path = 'dashadmin' element = {<Dashboard/>}/>
            <Route path = 'dashadmin/all-products' element = {<AllProducts/>}/>
            <Route path = 'dashadmin/add-product' element = {<AddProducts/>}/>
            <Route path = 'dashadmin/users' element = {<Users/>}/>
        </Route>
        

        <Route path = 'login' element = {<Login/>}/>
        <Route path = 'forgotpassword' element = {<ForgotPassword/>}/>
        <Route path = 'signup' element = {<Signup/>}/>
    </Routes>
};

export default Routers;