import { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase.config';

const useAuth = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const ADMIN_EMAIL = 'admin@gmail.com';

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
      } else {
        setCurrentUser(null);
      }
    });
  }, []);

  const isAdmin = () => {
    return currentUser && currentUser.email === ADMIN_EMAIL;
  };

  return { currentUser, isAdmin };
};

export default useAuth;
